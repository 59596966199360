import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {
    BottomNavigation,
    BottomNavigationAction,
    Box,
    Button,
    Container,
    CssBaseline,
    Dialog,
    DialogActions,
    DialogTitle,
    Grid,
    Paper, Zoom
} from "@mui/material";
import {DataGrid, GridColDef, GridRowSelectionModel} from '@mui/x-data-grid';
import BilliardService from "../../services/BilliardService";
import {AxiosResponse} from "axios";

const Trainings: React.FC = () => {
    let pageSize = 15;
    const navigate = useNavigate();
    const {playerId} = useParams();
    const [trainings, setTrainings] = useState([])
    const [rowCount, setRowCount] = useState(0)
    const [selectedIds, setSelectedIds] = useState<GridRowSelectionModel>([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const columns: GridColDef[] = [
        {field: 'id', headerName: 'ID', width: 50},
        {field: 'formattedStartDate', headerName: 'Date'},
        {field: 'totalPots', headerName: 'Pots', width: 50},
        {field: 'totalInnings', headerName: 'Innings', width: 50},
        {field: 'average', headerName: 'Average', width: 50}
    ];

    useEffect(() => {
        retrieveTrainings(playerId, 100)
    }, []);

    const retrieveTrainings = (playerId: any, limit: any) => {
        BilliardService.retrieveTrainings(playerId, limit)
            .then((trainings: any) => {
                setTrainings(trainings.data)
                setRowCount(Math.floor(trainings.data.length / rowCount) + (trainings.data.length % pageSize > 0 ? 1 : 0))
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    const confirmDelete = () => {
        setOpenDeleteDialog(true);
    };

    const handleClose = () => {
        setOpenDeleteDialog(false);
    };

    const deleteTrainings = () => {
        BilliardService.deleteTrainings(selectedIds)
            .then((res: AxiosResponse) => {
                retrieveTrainings(playerId, 100)
                handleClose()
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    return (
        <Container maxWidth="sm">
            {/*<Zoom in={true} style={{transitionDelay: '100ms'}}>
                <div style={{height: 500, width: '100%'}}>
                    <DataGrid
                        rows={trainings}
                        columns={columns}
                        pageSize={pageSize}
                        rowsPerPageOptions={[rowCount]}
                        checkboxSelection
                        onSelectionModelChange={itm => setSelectedIds(itm)}
                        sx={{
                            boxShadow: 4,
                            bgcolor: 'white',
                            border: 1,
                            borderColor: 'primary.light',
                            '& .MuiDataGrid-cell:hover': {
                                color: 'primary.main',
                            },
                        }}/>
                    <Grid container spacing={3} style={{marginTop: 16}} alignItems="center">
                        <Grid item xs={4}></Grid>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={4}>
                            <Button variant="contained" onClick={confirmDelete} style={{fontSize: 18}}>Delete</Button>
                        </Grid>
                    </Grid>

                    <Dialog open={openDeleteDialog} onClose={handleClose} aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">
                            {"Are you sure about deleting the trainings?"}
                        </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleClose}>No</Button>
                            <Button onClick={deleteTrainings} autoFocus>Yes</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </Zoom>

            <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={3}>
                <BottomNavigation showLabels style={{
                    background: 'linear-gradient(to right, #4ca1af,transparent)',
                    backgroundColor: '#c4e0e5'
                }}
                                  onChange={(event, newValue) => {
                                      if (newValue === 0) {
                                          navigate(`/player/${playerId}`)
                                      } else if (newValue === 1) {
                                          navigate(`/trainings/${playerId}`)
                                      } else if (newValue === 2) {
                                          navigate(`/`)
                                      }
                                  }}>
                    <BottomNavigationAction label="Player"/>
                    <BottomNavigationAction label="Trainings"/>
                    <BottomNavigationAction label="Logout"/>
                </BottomNavigation>
            </Paper>*/}

        </Container>
    );

}

export default Trainings;
