import http from "../http-common";
import PlayerData from "../types/PlayerData";
import TrainingData from "../types/TrainingData";
import {UpdateTrainingRequest} from "./request/UpdateTrainingRequest";
import {LoginRequest} from "./request/LoginRequest";
import {DeleteTrainingsRequest} from "./request/DeleteTrainingsRequest";
import {SocialMediaLoginRequest} from "./request/SocialMediaLoginRequest";

const login = (id: any, password: any) => {
    return http.post<PlayerData>(`/v1/player/${id}/login`, new LoginRequest(password));
};

const socialMediaLogin = (id: any, name: any, provider: any) => {
    return http.post<PlayerData>(`/v1/player/social-media-login`, new SocialMediaLoginRequest(id, name, provider));
};
const startTraining = (id: any) => {
    return http.post<any>(`/v1/training/start/${id}`);
};

const endTraining = (id: any) => {
    return http.post<any>(`/v1/training/end/${id}`);
};

const updateTraining = (id: any, potCount: any) => {
    return http.post<TrainingData>(`/v1/training/update/${id}`, new UpdateTrainingRequest(potCount));
};

const retrieveTraining = (id: any) => {
    return http.get<TrainingData>(`/v1/training/${id}`);
};

const retrieveTrainings = (playerId: any, limit: any) => {
    return http.get<Array<TrainingData>>(`/v1/training/player/${playerId}/${limit}`);
};

const deleteTrainings = (ids: any) => {
    return http.post(`/v1/training`, new DeleteTrainingsRequest(ids));
};

const getPlayer = (id: any) => {
    return http.get<PlayerData>(`/v1/player/${id}`);
};

const BilliardService = {
    login,
    socialMediaLogin,
    startTraining,
    endTraining,
    updateTraining,
    retrieveTraining,
    retrieveTrainings,
    deleteTrainings,
    getPlayer
};

export default BilliardService;
