import BilliardService from "../../services/BilliardService";
import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import PlayerData from "../../types/PlayerData";
import {
    Avatar,
    BottomNavigation,
    BottomNavigationAction,
    Button,
    Container, createTheme,
    CssBaseline,
    Grid,
    Paper, responsiveFontSizes,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, ThemeProvider, Typography,
    Zoom
} from "@mui/material";

const Player: React.FC = () => {
    const navigate = useNavigate();
    const [player, setPlayer] = useState<PlayerData | null>()
    const {id} = useParams();

    const retrievePlayer = (id: any) => {
        BilliardService.getPlayer(id)
            .then((player: any) => {
                setPlayer(player.data)
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    useEffect(() => {
        retrievePlayer(id)
    }, []);

    const startNewTraining = (event: React.FormEvent<HTMLButtonElement>) => {
        event.preventDefault();
        BilliardService.startTraining(player?.id)
            .then((trainingId: any) => {
                navigate("/training/" + trainingId.data);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    const resumeTraining = (event: React.FormEvent<HTMLButtonElement>) => {
        event.preventDefault();
        navigate("/training/" + player?.ongoingTrainingId);
    }

    return (
        <div className="submit-form">
            <div>
                <div className="form-group">
                    {player ? (
                        <Container maxWidth="sm">
                            <CssBaseline/>
                            <Zoom in={true} style={{transitionDelay: '100ms'}}>
                                <Grid container spacing={4} alignItems="center">
                                    <Grid item xs={4} sm={1}>
                                        <Avatar src={globalThis.window.pic}
                                                sx={{width: 144, height: 144, border: '1px solid lightgray'}}/>
                                    </Grid>
                                    <Grid item xs={8} sm={11}>
                                        <Typography fontSize={36} fontFamily={'papyrus'} sx={{ wordBreak: "break-word" }}>{player!.name}</Typography>
                                    </Grid>

                                    <Grid item xs={0} sm={0}></Grid>
                                    <Grid item xs={12} sm={12} maxWidth="sm">
                                        <TableContainer component={Paper} elevation={8}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={{fontSize: 12}}>Total Pots</TableCell>
                                                        <TableCell style={{fontSize: 12}}>Total Innings</TableCell>
                                                        <TableCell style={{fontSize: 12}}>Overall Average</TableCell>
                                                        <TableCell style={{fontSize: 12}}>Last Game Average</TableCell>
                                                        <TableCell style={{fontSize: 12}}>Last 3 Games
                                                            Average</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>{player!.totalPots}</TableCell>
                                                        <TableCell>{player!.totalInnings}</TableCell>
                                                        <TableCell>{player!.overallAverage}</TableCell>
                                                        <TableCell>{player!.lastGameAverage}</TableCell>
                                                        <TableCell>{player!.last3GamesAverage}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                    <Grid item xs={0} sm={0}></Grid>

                                    <Grid item xs={12} sm={12}>
                                        {player.ongoingTrainingId === null ?
                                            (
                                                <Button variant="contained" onClick={startNewTraining}
                                                        sx={{width: 196}}>Start New Training</Button>
                                            ) :
                                            (
                                                <Button variant="contained" onClick={resumeTraining}
                                                        style={{fontFamily: 'papyrus'}}>Resume Training</Button>
                                            )}
                                    </Grid>
                                </Grid>
                            </Zoom>

                            <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={3}>
                                <BottomNavigation showLabels style={{
                                    background: 'linear-gradient(to right, #4ca1af,transparent)',
                                    backgroundColor: '#c4e0e5'
                                }}
                                                  onChange={(event, newValue) => {
                                                      if (newValue === 0) {
                                                          navigate(`/player/${id}`)
                                                      } else if (newValue === 1) {
                                                          navigate(`/trainings/${id}`)
                                                      } else if (newValue === 2) {
                                                          navigate(`/`)
                                                      }
                                                  }}>
                                    <BottomNavigationAction label="Player"/>
                                    <BottomNavigationAction label="Trainings"/>
                                    <BottomNavigationAction label="Logout"/>
                                </BottomNavigation>
                            </Paper>
                        </Container>
                    ) : (<div></div>)
                    }
                </div>
            </div>
        </div>
    )

};

export default Player;
