export class SocialMediaLoginRequest {
    id: string
    name: string
    provider: string

    constructor(id: string, name: string, provider: string) {
        this.id = id;
        this.name = name;
        this.provider = provider;
    }
}
