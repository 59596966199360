import React, {useEffect} from 'react';
import {HashRouter, Navigate, Route, Routes} from "react-router-dom";
import './App.css';
import {routes as appRoutes} from "./routes";

function App() {
    useEffect(() => {
        if(window.location.hash.indexOf("#_") > -1 ) {
            window.location.hash = "";
        }
    });

    return (
        <div className="App">
            <HashRouter>
                <Routes>
                    {appRoutes.map((route) => (
                        <Route
                            key={route.key}
                            path={route.path}
                            element={<route.component/>}
                        />
                    ))}
                </Routes>
            </HashRouter>
        </div>
    );
}

export default App;
