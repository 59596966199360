import React from 'react';
import {BottomNavigation, BottomNavigationAction, Paper} from "@mui/material";
import {useNavigate} from "react-router-dom";

const BottomBar: React.FC = () => {
    const navigate = useNavigate();

    return (
        <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={3}>
            <BottomNavigation showLabels style={{
                background: 'linear-gradient(to right, #4ca1af,transparent)',
                backgroundColor: '#c4e0e5'
            }}
                              onChange={(event, newValue) => {
                                  if (newValue === 0) {
                                      navigate(`/player-v2/${localStorage.getItem('playerId')}`)
                                  } else if (newValue === 1) {
                                      navigate(`/player-v2/${localStorage.getItem('playerId')}/matches`)
                                  } else if (newValue === 2) {
                                      navigate(`/`)
                                  }
                              }}>
                <BottomNavigationAction label="Player"/>
                <BottomNavigationAction label="Matches"/>
                <BottomNavigationAction label="Logout"/>
            </BottomNavigation>
        </Paper>
    );
};

export default BottomBar;
