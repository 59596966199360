export class MatchRequest {
    requesterPlayerId: number;
    requestedPlayerId: number;
    raceTo: number;

    constructor(requesterPlayerId: number, requestedPlayerId: number, raceTo: number) {
        this.requesterPlayerId = requesterPlayerId;
        this.requestedPlayerId = requestedPlayerId;
        this.raceTo = raceTo;
    }
}
