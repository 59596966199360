export class RefereeNewMatchRequest {
    playerOneId: number;
    playerTwoId: number;
    raceTo: number;

    constructor(playerOneId: number, playerTwoId: number, raceTo: number) {
        this.playerOneId = playerOneId;
        this.playerTwoId = playerTwoId;
        this.raceTo = raceTo;
    }
}
