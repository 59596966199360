import React from 'react';
import {Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Zoom} from "@mui/material";
import MatchData from "../../types/MatchData";


interface MatchInfoProps {
    match: MatchData
}

const MatchInfo: React.FC<MatchInfoProps> = ({match}) => {

    return (
        <Grid item xs={12} sm={12} maxWidth="sm">
            <TableContainer component={Paper} elevation={8}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{textAlign: 'center', fontSize: 10}}>Innings-1</TableCell>
                            <TableCell style={{textAlign: 'center', fontSize: 10}}>Defense-1</TableCell>
                            <TableCell style={{textAlign: 'center', fontSize: 10}}>Innings-2</TableCell>
                            <TableCell style={{textAlign: 'center', fontSize: 10}}>Defense-2</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{textAlign: 'center'}}>
                                <div>{match?.playerOneInnings}</div>
                            </TableCell>
                            <TableCell style={{textAlign: 'center'}}>
                                <div>{match?.playerOneDefenses}</div>
                            </TableCell>
                            <TableCell style={{textAlign: 'center'}}>
                                <div>{match?.playerTwoInnings}</div>
                            </TableCell>
                            <TableCell style={{textAlign: 'center'}}>
                                <div>{match?.playerTwoDefenses}</div>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>

    );
};

export default MatchInfo;

