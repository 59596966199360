import React, {useState} from 'react';
import {Alert, AlertTitle, BottomNavigation, BottomNavigationAction, Paper, Snackbar} from "@mui/material";
import {useNavigate} from "react-router-dom";
import MatchData from "../../types/MatchData";
import {AlertColor} from "@mui/material/Alert/Alert";

interface MessageProps {
    severity: AlertColor,
    title: string,
    msg: string,
    openInfo: boolean,
    setOpenInfo: any
}

const Message: React.FC<MessageProps> = ({severity, title, msg, openInfo, setOpenInfo}) => {

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenInfo(false);
    };

    return (
        <Snackbar open={openInfo} autoHideDuration={10000} onClose={handleClose}>
            <Alert severity={severity} onClose={handleClose} sx={{ width: '100%' }}>
                <AlertTitle>{title}</AlertTitle>
                {msg}
            </Alert>
        </Snackbar>
    );
};

export default Message;
