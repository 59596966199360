import http from "../http-common";
import MatchData from "../types/MatchData";
import {RefereeNewMatchRequest} from "./request/RefereeNewMatchRequest";

const requestMatch = (playerOneId: number, playerTwoId: number, raceTo: number) => {
    return http.post<MatchData>(`/v1/match/referee/request`, new RefereeNewMatchRequest(playerOneId, playerTwoId, raceTo));
};

const finishInning = (playerId: number) => {
    return http.put<MatchData>(`/v1/match/finish-inning/${playerId}?referee=true`);
};

const defense = (playerId: number) => {
    return http.put<MatchData>(`/v1/match/defense/${playerId}?referee=true`);
};

const win = (playerId: number) => {
    return http.put<MatchData>(`/v1/match/win/${playerId}?referee=true`);
};

const switchPlayers = (matchId: number) => {
    return http.put<MatchData>(`/v1/match/switch-players/${matchId}`);
};

const takeControl = (refereeId: number, matchId: number) => {
    return http.post(`/v1/match/referee/${refereeId}/take-control/${matchId}`);
};

const leaveControl = (refereeId: number, matchId: number) => {
    return http.post(`/v1/match/referee/${refereeId}/leave-control/${matchId}`);
};

const RefereeService = {
    requestMatch,
    finishInning,
    defense,
    win,
    switchPlayers,
    takeControl,
    leaveControl
};

export default RefereeService;
