import React, {useEffect, useState} from "react";
import {
    Autocomplete, Box,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogTitle,
    Grid,
    TextField,
    Zoom
} from "@mui/material";
import {
    DataGrid,
    GridColDef, GridFilterModel,
    GridRowSelectionModel,
    GridToolbarQuickFilter
} from "@mui/x-data-grid";
import {useNavigate} from "react-router-dom";
import RefereeBottomBar from "../RefereeBottomBar";
import RefereeService from "../../services/RefereeService";
import Billiard2Service from "../../services/BilliardV2Service";

const RefereeNewMatch: React.FC = () => {

    const raceToValues = [ '1', '2', '3', '4', '5']

    let pageSize = 15;
    const navigate = useNavigate();
    const [raceTo, setRaceTo] = React.useState<string | null>(raceToValues[0]);
    const [rowCount, setRowCount] = useState(0)
    const [players, setPlayers] = useState([])
    const [selectedId, setSelectedId] = useState<GridRowSelectionModel>([])
    const [openRequestDialog, setOpenRequestDialog] = useState(false);

    const columns: GridColDef[] = [
        {field: 'id', headerName: 'ID', width: 70},
        {field: 'name', headerName: 'Name', width: 100},
        {field: 'skill', headerName: 'Skill', width: 70},
        {field: 'winningPercentage', headerName: 'Win%', width: 70}
    ];

    useEffect(() => {
        search('')
    }, []);

    const search = (query: string) => {
        Billiard2Service.search(query)
            .then((players: any) => {
                setPlayers(players.data)
                setRowCount(Math.floor(players.data.length / rowCount) + (players.data.length % 3 > pageSize ? 1 : 0))
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const confirmRequest = () => {
        setOpenRequestDialog(true);
    };

    const handleClose = () => {
        setOpenRequestDialog(false);
    };

    const requestMatch = () => {
        if (selectedId.length !== 2) {
            alert('Two players must be selected!')
            return
        }
        RefereeService.requestMatch(Number(selectedId[0]), Number(selectedId[1]), Number(raceTo))
            .then((match: any) => {
                navigate(`/referee/match/${match.data.id}`)
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    function QuickSearchToolbar() {
        return (
            <Box sx={{ p: 0.5, pb: 0, }}>
                <GridToolbarQuickFilter/>
            </Box>
        );
    }

    let lastQuery = '';
    const onFilterChange = React.useCallback((filterModel: GridFilterModel) => {
        const query = filterModel.quickFilterValues?.[0];
        if (query !== undefined && query.length > 3) {
            search(query)
            lastQuery = query;

        }  else if ((query === undefined || query.length === 0) && lastQuery.length > 0) {
            search('')
        }
    }, []);

    return (
        <Container maxWidth="sm">
            <Zoom in={true} style={{transitionDelay: '100ms'}}>
                <div style={{height: 550, width: '100%'}}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={12} style={{fontSize: 48}}>Players</Grid>
                    </Grid>
                    <DataGrid
                        columns={columns}
                        rows={players}
                        slots={{ toolbar: QuickSearchToolbar }}
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: 10, page: 0 },
                            },
                        }}
                        keepNonExistentRowsSelected
                        onRowSelectionModelChange={(newRowSelectionModel: GridRowSelectionModel) => {setSelectedId(newRowSelectionModel)}}
                        filterMode="server"
                        onFilterModelChange={onFilterChange}
                        checkboxSelection={true}
                        sx={{
                            boxShadow: 4,
                            bgcolor: 'white',
                            border: 1,
                            borderColor: 'primary.light',
                            '& .MuiDataGrid-cell:hover': {
                                color: 'primary.main',
                            },
                        }}/>

                    <Grid container spacing={3} style={{marginTop: 16}} alignItems="center">
                        <Grid item xs={6}></Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" onClick={confirmRequest} style={{fontFamily: 'papyrus', fontSize: 18}}>Create</Button>
                        </Grid>
                    </Grid>

                    <Dialog open={openRequestDialog} onClose={handleClose} aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            PaperProps={{ sx: {height: 400} }}>
                        <DialogTitle id="alert-dialog-title">
                            {"Are you sure about requesting match?"}
                        </DialogTitle>
                        <DialogActions>
                            <Autocomplete
                                disablePortal
                                value={raceTo}
                                onChange={(event: any, newValue: string | null) => {
                                    setRaceTo(newValue);
                                }}
                                id="race-to-combo"
                                options={raceToValues}
                                sx={{ width: 200 }}
                                renderInput={(params) => <TextField {...params} label="Race To" />}
                            />
                            <Button onClick={handleClose} style={{fontFamily: 'papyrus'}}>No</Button>
                            <Button onClick={requestMatch} style={{fontFamily: 'papyrus'}} autoFocus>Yes</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </Zoom>

            <RefereeBottomBar/>
        </Container>
    );
};

export default RefereeNewMatch;
