import React from "react";
import Billiard2Service from "../../services/BilliardV2Service";
import MatchData from "../../types/MatchData";


export default class MatchFunctions {

    static retrieveMatch = (id : any, setMatch: any, setMatchInfo: any, setPlayer1TurnDisplay: any, setPlayer2TurnDisplay: any, setSwitchTurnDisplay: any) => {
        Billiard2Service.getMatch(id)
            .then((matchData: any) => {
                setMatch(matchData.data)
                MatchFunctions.setPlayerTurnDisplays(matchData.data, setPlayer1TurnDisplay, setPlayer2TurnDisplay, setSwitchTurnDisplay)
                if (matchData.data.winner !== null) {
                    setMatchInfo(`Winner is ${matchData.data.winner.name}`)
                }
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    static setPlayerTurnDisplays = (matchData: MatchData, setPlayer1TurnDisplay: any, setPlayer2TurnDisplay: any, setSwitchTurnDisplay: any) => {
        if (matchData.winner !== null) {
            setPlayer1TurnDisplay('none')
            setPlayer2TurnDisplay('none')
        } else {
            if (matchData.playerOne.id === matchData.turnPlayerId) {
                setPlayer1TurnDisplay('inline')
                setPlayer2TurnDisplay('none')
            } else {
                setPlayer1TurnDisplay('none')
                setPlayer2TurnDisplay('inline')
            }
        }
        setSwitchTurnDisplay((matchData.playerOneInnings === 0 && matchData.playerTwoInnings === 0
            && matchData.playerOneDefenses === 0 && matchData.playerTwoDefenses === 0) ? 'inline' : 'none')
    }
}
