import React, {useState} from 'react';
import {Button, Container, Grid, TextField, Zoom} from "@mui/material";
import {useNavigate} from "react-router-dom";
import Message from "../info/Message";
import BilliardV2Service from "../../services/BilliardV2Service";
import Billiard2Service from "../../services/BilliardV2Service";
import httpCommon from "../../http-common";
import jwtDecode from "jwt-decode";
import JwtData from "../../types/JwtData";

const Register: React.FC = () => {
    const navigate = useNavigate();
    const [usernameFormatError, setUsernameFormatError] = useState(false);
    const [emailFormatError, setEmailFormatError] = useState(false);
    const [passwordFormatError, setPasswordFormatError] = useState(false);
    const [passwordMatchError, setPasswordMatchError] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [repassword, setRepassword] = useState('');

    const register = (event: React.FormEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if (username.length < 6 || username.length > 10 || !isLowercaseOnly(username)) {
            setUsernameFormatError(true)
            return
        }
        if (password.length < 6 || password.length > 10 || !isNumbersOnly(password)) {
            setPasswordFormatError(true)
            return
        }
        if (password !== repassword) {
            setPasswordMatchError(true)
            return
        }
        if (!validEmail(email)) {
            setEmailFormatError(true)
            return
        }
        BilliardV2Service.register(username, email, password)
            .then((playerData: any) => {
                Billiard2Service.authenticate(username, password)
                    .then((response: any) => {
                        localStorage.setItem('jwttoken', response.data.jwttoken);
                        httpCommon.defaults.headers.Authorization = `Bearer ${response.data.jwttoken}`

                        const token = jwtDecode<JwtData>(response.data.jwttoken);
                        localStorage.setItem('playerId', token.id)
                        localStorage.setItem('avatar', token.avatar)
                        navigate(`/player-v2/${token.id}`)
                    })
                    .catch((e: Error) => {
                        console.log(e);
                    });
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    const isLowercaseOnly = (s: string) => {
        const nonLowercaseLetters = /[^a-z]/g;
        return !nonLowercaseLetters.test(s);
    };

    function isNumbersOnly(s: string) {
        return /^\d+$/.test(s);
    }

    function validEmail(s: string) {
        return /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i.test(s)
    }

    return (
        <Container maxWidth="sm">
            <Message severity={'error'} title={'Username Format Error!'} msg={'Username must be between 6 and 10 lowercase letters and must not contain any spaces!'} openInfo={usernameFormatError} setOpenInfo={setUsernameFormatError}/>
            <Message severity={'error'} title={'E-mail Format Error!'} msg={'Invalid e-mail format!'} openInfo={emailFormatError} setOpenInfo={setEmailFormatError}/>
            <Message severity={'error'} title={'Password Format Error!'} msg={'Password must be between 6 and 10 characters and only contain numbers!'} openInfo={passwordFormatError} setOpenInfo={setPasswordFormatError}/>
            <Message severity={'error'} title={'Password Match Error!'} msg={'Passwords do not match, please check!'} openInfo={passwordMatchError} setOpenInfo={setPasswordMatchError}/>
            <Zoom in={true} style={{transitionDelay: '100ms'}}>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} style={{fontSize: 48}}>Register</Grid>
                    <Grid item xs={12}>
                        <img src="images/8ball.png" width={108}/>
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}>
                        <TextField id="email" placeholder="E-mail" variant="outlined"
                                   value={email}
                                   onChange={(event) => setEmail(event.target.value)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="username" placeholder="Username" variant="outlined"
                                   value={username}
                                   onChange={(event) => setUsername(event.target.value)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="password" placeholder="Password" variant="outlined" type="password"
                                   value={password}
                                   onChange={(event) => setPassword(event.target.value)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="repassword" placeholder="Retype Password" variant="outlined" type="password"
                                   value={repassword}
                                   onChange={(event) => setRepassword(event.target.value)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" onClick={register}
                                style={{fontFamily: 'papyrus', fontSize: 18, width: 196}}>Register</Button>
                    </Grid>
                </Grid>
            </Zoom>
        </Container>
    );
};

export default Register;
