import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Button, Container, Grid, TextField, Zoom} from "@mui/material";
import RefereeService from "../../services/RefereeService";
import BilliardV2Service from "../../services/BilliardV2Service";
import httpCommon from "../../http-common";
import jwtDecode from "jwt-decode";
import JwtData from "../../types/JwtData";

const RefereeLogin: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.clear()
    }, []);

    const authenticate = (event: React.FormEvent<HTMLButtonElement>) => {
        event.preventDefault();
        BilliardV2Service.authenticate(username, password)
            .then((response: any) => {
                localStorage.setItem('jwttoken', response.data.jwttoken);
                httpCommon.defaults.headers.Authorization = `Bearer ${response.data.jwttoken}`

                const token = jwtDecode<JwtData>(response.data.jwttoken);
                localStorage.setItem('refereeId', token.id)
                localStorage.setItem('refereeName', token.sub)
                navigate(`/referee/match/select`)
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    return (
        <Container maxWidth="sm">
            <Zoom in={true} style={{transitionDelay: '100ms'}}>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} style={{fontSize: 48}}>Referee Login</Grid>
                    <Grid item xs={12}>
                        <img src="images/8ball.png" width={108}/>
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}>
                        <TextField id="username" placeholder="Username" variant="outlined"
                                   value={username}
                                   onChange={(event) => setUsername(event.target.value)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="password" placeholder="Password" variant="outlined" type="password"
                                   value={password}
                                   onChange={(event) => setPassword(event.target.value)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" onClick={authenticate}
                                style={{fontFamily: 'papyrus', fontSize: 18, width: 196}}>Login</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained"onClick={() => navigate(`/`)}
                                style={{fontFamily: 'papyrus', fontSize: 18, width: 196}}>Player Login</Button>
                    </Grid>
                </Grid>
            </Zoom>
        </Container>
    );
};

export default RefereeLogin;
