import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import BilliardService from "../../services/BilliardService";
import TrainingData from "../../types/TrainingData";
import {
    BottomNavigation,
    BottomNavigationAction,
    Button,
    Container, debounce,
    Dialog,
    DialogActions,
    DialogTitle,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Zoom
} from "@mui/material";

const Training: React.FC = () => {
    const navigate = useNavigate();
    const [pressedButton, setPressedButton] = useState('');
    const [averageZoom, setAverageZoom] = useState(true);
    const [potCountZoom, setPotCountZoom] = useState(true);
    const [increaseButtonDisabled, setIncreaseButtonDisabled] = useState(false);
    const [decreaseButtonDisabled, setDecreaseButtonDisabled] = useState(false);
    const [openFinishDialog, setOpenFinishDialog] = useState(false);
    const [training, setTraining] = useState<TrainingData | null>()
    const {id} = useParams();
    const [currentPotCount, setCurrentPotCount] = useState<number>(0);

    useEffect(() => {
        retrieveTraining()
    }, []);

    const retrieveTraining = () => {
        BilliardService.retrieveTraining(id)
            .then((training: any) => {
                setTraining(training.data)
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    const increasePotCount = () => {
        if (increaseButtonDisabled) {
            return
        }
        if (currentPotCount < 15) {
            setPressedButton('+')
            setIncreaseButtonDisabled(true)
            setPotCountZoom(false)
        }
    };

    const decreasePotCount = () => {
        if (decreaseButtonDisabled) {
            return
        }
        if (currentPotCount > 0) {
            setPressedButton('-')
            setDecreaseButtonDisabled(true)
            setPotCountZoom(false)
        }
    };

    const update = () => {
        setAverageZoom(false)
    };

    const finish = () => {
        BilliardService.endTraining(training?.id).then(r => {
            navigate(`/player/${training?.playerId}`)
        })
    };

    const confirmFinish = () => {
        setOpenFinishDialog(true);
    };

    const handleClose = () => {
        setOpenFinishDialog(false);
    };

    const avgTransitionEnd = () => {
        if (!averageZoom) {
            BilliardService.updateTraining(id, currentPotCount)
                .then((training: any) => {
                    setTraining(training.data)
                    setCurrentPotCount(0);
                    setAverageZoom(true)
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        }
    };

    const potCountTransitionEnd = () => {
        if (!potCountZoom) {
            if (pressedButton === '+') {
                setCurrentPotCount(currentPotCount + 1);
                setIncreaseButtonDisabled(false)
            } else if (pressedButton === '-') {
                setCurrentPotCount(currentPotCount - 1);
                setDecreaseButtonDisabled(false)
            }
            setPotCountZoom(true)
        }
    }

    return (
        training ? (
            <Container maxWidth="sm">
                <Zoom in={true} style={{transitionDelay: '100ms'}}>
                    <Grid container spacing={3} style={{marginTop: 16}} alignItems="center">
                        <Grid item xs={4}>
                            <Button variant="contained" onClick={debounce(decreasePotCount, 100)} disabled={decreaseButtonDisabled}
                                    style={{fontFamily: 'papyrus', fontSize: 48, width: 72, height: 72}}>-</Button>
                        </Grid>
                        <Grid item xs={4} style={{fontSize: 108}}>
                            <Zoom in={potCountZoom} style={{transitionDelay: '100ms'}} onTransitionEnd={potCountTransitionEnd}>
                                <div>{currentPotCount}</div>
                            </Zoom>
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant="contained" onClick={debounce(increasePotCount, 100)} disabled={increaseButtonDisabled}
                                    style={{fontFamily: 'papyrus', fontSize: 48, width: 72, height: 72}}>+</Button>
                        </Grid>

                        <Grid item xs={12}>
                            <Button variant="contained" onClick={update}
                                    style={{fontFamily: 'papyrus', fontSize: 36}}>Enter</Button>
                        </Grid>

                        <Grid item xs={12} style={{marginTop: 20}}>
                            <TableContainer component={Paper} elevation={8}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>ID</TableCell>
                                            <TableCell>Total Pots</TableCell>
                                            <TableCell>Total Innings</TableCell>
                                            <TableCell>Average</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>{training!.id}</TableCell>
                                            <TableCell>{training!.totalPots}</TableCell>
                                            <TableCell>{training!.totalInnings}</TableCell>
                                            <TableCell style={{color: "red", fontSize: 24}}>
                                                <Zoom in={averageZoom} style={{transitionDelay: '100ms'}} onTransitionEnd={avgTransitionEnd}>
                                                    <div>{training!.average}</div>
                                                </Zoom>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid item xs={12} style={{marginTop: 48}}>
                            <Button variant="contained" onClick={confirmFinish}
                                    style={{fontFamily: 'papyrus', fontSize: 36}}>Finish</Button>
                        </Grid>
                    </Grid>
                </Zoom>

                <Dialog open={openFinishDialog} onClose={handleClose} aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        {"Are you sure about finishing the training?"}
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={handleClose}>No</Button>
                        <Button onClick={finish} autoFocus>Yes</Button>
                    </DialogActions>
                </Dialog>

                <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={3}>
                    <BottomNavigation showLabels style={{
                        background: 'linear-gradient(to right, #4ca1af,transparent)',
                        backgroundColor: '#c4e0e5'
                    }}
                                      onChange={(event, newValue) => {
                                          if (newValue === 0) {
                                              navigate(`/player/${training?.playerId}`)
                                          } else if (newValue === 1) {
                                              navigate(`/trainings/${training?.playerId}`)
                                          } else if (newValue === 2) {
                                              navigate(`/`)
                                          }
                                      }}>
                        <BottomNavigationAction label="Player"/>
                        <BottomNavigationAction label="Trainings"/>
                        <BottomNavigationAction label="Logout"/>
                    </BottomNavigation>
                </Paper>

            </Container>
        ) : (<div></div>)

    );

}

export default Training;
