import React, {useEffect, useState} from "react";
import {
    Avatar,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogTitle,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Zoom
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import Billiard2Service from "../../services/BilliardV2Service";
import PlayerV2Data from "../../types/PlayerV2Data";
import MatchRequestData from "../../types/MatchRequestData";
import BottomBar from "../BottomBar";

const MatchRequest: React.FC = () => {

    const navigate = useNavigate();
    const {playerId} = useParams();
    const [opponent, setOpponent] = useState<PlayerV2Data | null>()
    const [matchRequest, setMatchRequest] = useState<MatchRequestData | null>()
    const [openCancelDialog, setOpenCancelDialog] = useState(false);
    const [openAcceptDialog, setOpenAcceptDialog] = useState(false);

    useEffect(() => {
        retrieveMatchRequest()
    }, []);
    const retrieveMatchRequest = () => {
        Billiard2Service.retrieveMatchRequest(playerId)
            .then((matchRequest: any) => {
                if (matchRequest.data === '' || matchRequest.data.state === 'CANCELLED') {
                    navigate(`/player-v2/${localStorage.getItem('playerId')}`)
                }
                setMatchRequest(matchRequest.data)
                if (matchRequest.data.requesterPlayer.id == playerId) {
                    setOpponent(matchRequest.data.requestedPlayer)
                } else {
                    setOpponent(matchRequest.data.requesterPlayer)
                }
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const confirmCancelRequest = () => {
        setOpenCancelDialog(true);
    };

    const handleCancelClose = () => {
        setOpenCancelDialog(false);
    };

    const confirmAcceptRequest = () => {
        setOpenAcceptDialog(true);
    };

    const handleAcceptClose = () => {
        setOpenAcceptDialog(false);
    };

    const refreshWhileWaiting = () => {
        Billiard2Service.retrieveMatchRequestById(matchRequest?.id)
            .then((matchRequest: any) => {
                if (matchRequest.data.state === 'ACCEPTED') {
                    Billiard2Service.getMatchByRequestId(matchRequest.data.id)
                        .then((matchData: any) => {
                            navigate(`/match/${matchData.data.id}`)
                        })
                        .catch((e: Error) => {
                            console.log(e);
                        });
                } else if (matchRequest.data.state === 'CANCELLED') {
                    navigate(`/player-v2/${localStorage.getItem('playerId')}`)
                }
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const cancelRequest = () => {
        Billiard2Service.cancelMatchRequest(matchRequest?.id)
            .then(() => {
                navigate(`/player-v2/${localStorage.getItem('playerId')}`)
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    const acceptRequest = () => {
        Billiard2Service.acceptMatchRequest(matchRequest?.id)
            .then((match: any) => {
                navigate(`/match/${match.data.id}`)
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    return (
        <div>
            {opponent ? (
                <Container maxWidth="sm">
                    <Zoom in={true} style={{transitionDelay: '100ms'}}>
                        <div style={{height: 500, width: '100%'}}>
                            <Typography fontSize={24} fontFamily={'papyrus'}>Match Request (Race To: {matchRequest!.raceTo})</Typography>
                            <Grid container spacing={4} alignItems="center">
                                <Grid item xs={4} sm={1}>
                                    <Avatar src={`images/${opponent?.id}.png`}
                                            sx={{width: 144, height: 144, border: '1px solid lightgray'}}/>
                                </Grid>
                                <Grid item xs={8} sm={11}>
                                    <Typography fontSize={36} fontFamily={'papyrus'}
                                                sx={{wordBreak: "break-word"}}>{opponent!.name}</Typography>
                                </Grid>

                                <Grid item xs={0} sm={0}></Grid>
                                <Grid item xs={12} sm={12} maxWidth="sm">
                                    <TableContainer component={Paper} elevation={8}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{fontSize: 12}}>Innings</TableCell>
                                                    <TableCell style={{fontSize: 12}}>Defenses</TableCell>
                                                    <TableCell style={{fontSize: 12}}>Skill</TableCell>
                                                    <TableCell style={{fontSize: 12}}>Winning %</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>{opponent!.totalInnings}</TableCell>
                                                    <TableCell>{opponent!.totalDefenses}</TableCell>
                                                    <TableCell>{opponent!.skill}</TableCell>
                                                    <TableCell>{opponent!.winningPercentage}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>

                            <Grid container spacing={3} style={{marginTop: 16}} alignItems="center">
                                <Grid item xs={6}>
                                    {matchRequest?.requestedPlayer.id == playerId ?
                                        (
                                            <Button variant="contained" onClick={confirmAcceptRequest}
                                                    style={{fontFamily: 'papyrus', fontSize: 18}}>Accept</Button>
                                        ) :
                                        (
                                            <Button variant="contained" onClick={refreshWhileWaiting}
                                                    style={{fontFamily: 'papyrus', fontSize: 18}}>Refresh</Button>
                                        )
                                    }
                                </Grid>
                                <Grid item xs={6}>
                                    <Button variant="contained" onClick={confirmCancelRequest}
                                            style={{fontFamily: 'papyrus', fontSize: 18}}>Cancel</Button>
                                </Grid>
                            </Grid>
                        </div>
                    </Zoom>

                    <Dialog open={openCancelDialog} onClose={handleCancelClose} aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">
                            {"Are you sure about cancelling match?"}
                        </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleCancelClose} style={{fontFamily: 'papyrus'}}>No</Button>
                            <Button onClick={cancelRequest} style={{fontFamily: 'papyrus'}} autoFocus>Yes</Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={openAcceptDialog} onClose={handleAcceptClose} aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">
                            {"Are you sure about accepting match?"}
                        </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleAcceptClose} style={{fontFamily: 'papyrus'}}>No</Button>
                            <Button onClick={acceptRequest} style={{fontFamily: 'papyrus'}} autoFocus>Yes</Button>
                        </DialogActions>
                    </Dialog>

                    <BottomBar/>
                </Container>) : (<div></div>)
            }
        </div>
    );
};

export default MatchRequest;
