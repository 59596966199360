import React, {useEffect, useState} from "react";
import {
    Autocomplete,
    BottomNavigation,
    BottomNavigationAction, Box,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogTitle,
    Grid,
    Paper,
    TextField,
    Zoom
} from "@mui/material";
import Billiard2Service from "../../services/BilliardV2Service";
import {
    DataGrid,
    GridColDef,
    GridFilterModel,
    GridRowParams,
    GridRowSelectionModel,
    GridToolbarQuickFilter
} from "@mui/x-data-grid";
import {useNavigate} from "react-router-dom";
import BottomBar from "../BottomBar";
import Message from "../info/Message";

const NewMatchRequest: React.FC = () => {

    const raceToValues = ['1', '2', '3', '4', '5']

    let pageSize = 15;
    const navigate = useNavigate();
    const [raceTo, setRaceTo] = React.useState<string | null>(raceToValues[0]);
    const [rowCount, setRowCount] = useState(0)
    const [players, setPlayers] = useState([])
    const [selectedId, setSelectedId] = useState<GridRowSelectionModel>([])
    const [openRequestDialog, setOpenRequestDialog] = useState(false);
    const [undefinedChosenError, setUndefinedChosenError] = useState(false);

    const columns: GridColDef[] = [
        {field: 'id', headerName: 'ID'},
        {field: 'name', headerName: 'Name', width: 100},
        {field: 'skill', headerName: 'Skill', width: 70},
        {field: 'winningPercentage', headerName: 'Win%', width: 70}
    ];

    useEffect(() => {
        Billiard2Service.retrieveMatchRequest(localStorage.getItem('playerId'))
            .then((matchRequest: any) => {
                if (matchRequest.data === '' || matchRequest.data.state === 'CANCELLED') {
                    search('')
                } else if (matchRequest.data.state === 'REQUESTED') {
                    navigate(`/match-request/${localStorage.getItem('playerId')}`)
                }
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }, []);

    const search = (query: string) => {
        Billiard2Service.search(query)
            .then((players: any) => {
                setPlayers(players.data)
                setRowCount(Math.floor(players.data.length / rowCount) + (players.data.length % 3 > pageSize ? 1 : 0))
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const confirmRequest = () => {
        if (selectedId[0] === undefined) {
            setUndefinedChosenError(true)
        } else {
            setOpenRequestDialog(true);
        }
    };

    const handleClose = () => {
        setOpenRequestDialog(false);
    };

    const requestMatch = () => {
        Billiard2Service.requestMatch(localStorage.getItem('playerId'), selectedId[0], Number(raceTo))
            .then(() => {
                navigate(`/match-request/${localStorage.getItem('playerId')}`)
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    function QuickSearchToolbar() {
        return (
            <Box sx={{p: 0.5, pb: 0,}}>
                <GridToolbarQuickFilter/>
            </Box>
        );
    }

    let lastQuery = '';
    const onFilterChange = React.useCallback((filterModel: GridFilterModel) => {
        const query = filterModel.quickFilterValues?.[0];
        if (query !== undefined && query.length > 3) {
            search(query)
            lastQuery = query;
        } else if ((query === undefined || query.length === 0) && lastQuery.length > 0) {
            search('')
        }
    }, []);

    return (
        <Container maxWidth="sm">
            <Message severity={'error'} title={'Error!'} msg={'You must choose a player!'}
                     openInfo={undefinedChosenError} setOpenInfo={setUndefinedChosenError}/>
            <Zoom in={true} style={{transitionDelay: '100ms'}}>
                <div style={{height: 500, width: '100%'}}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={12} style={{fontSize: 48}}>Players</Grid>
                    </Grid>
                    <DataGrid
                        columns={columns}
                        rows={players}
                        slots={{toolbar: QuickSearchToolbar}}
                        initialState={{
                            pagination: {
                                paginationModel: {pageSize: 10, page: 0},
                            },
                        }}
                        onRowSelectionModelChange={(newRowSelectionModel: GridRowSelectionModel) => {
                            setSelectedId(newRowSelectionModel)
                        }}
                        filterMode="server"
                        onFilterModelChange={onFilterChange}
                        isRowSelectable={(params: GridRowParams) => params.id != localStorage.getItem('playerId')}
                        sx={{
                            boxShadow: 4,
                            bgcolor: 'white',
                            border: 1,
                            borderColor: 'primary.light',
                            '& .MuiDataGrid-cell:hover': {
                                color: 'primary.main',
                            },
                        }}/>

                    <Grid container spacing={3} style={{marginTop: 16}} alignItems="center">
                        <Grid item xs={6}></Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" onClick={confirmRequest}
                                    style={{fontFamily: 'papyrus', fontSize: 18}}>Request</Button>
                        </Grid>
                    </Grid>

                    <Dialog open={openRequestDialog} onClose={handleClose} aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            PaperProps={{sx: {height: 400}}}>
                        <DialogTitle id="alert-dialog-title">
                            {"Are you sure about requesting match?"}
                        </DialogTitle>
                        <DialogActions>
                            <Autocomplete
                                disablePortal
                                value={raceTo}
                                onChange={(event: any, newValue: string | null) => {
                                    setRaceTo(newValue);
                                }}
                                id="race-to-combo"
                                options={raceToValues}
                                sx={{width: 200}}
                                renderInput={(params) => <TextField {...params} label="Race To"/>}
                            />
                            <Button onClick={handleClose} style={{fontFamily: 'papyrus'}}>No</Button>
                            <Button onClick={requestMatch} style={{fontFamily: 'papyrus'}} autoFocus>Yes</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </Zoom>

            <BottomBar/>
        </Container>
    );
};

export default NewMatchRequest;
