import {useNavigate} from "react-router-dom";
import React, {useState} from "react";
import {Button, Container, Grid, TextField, Zoom} from "@mui/material";
import RefereeBottomBar from "../RefereeBottomBar";

const RefereeMatchSelect: React.FC = () => {

    const navigate = useNavigate();
    const [matchId, setMatchId] = useState('');

    const gotoMatch = (event: React.FormEvent<HTMLButtonElement>) => {
        event.preventDefault();
        navigate(`/referee/match/${matchId}`)
    }

    const createNewMatch = (event: React.FormEvent<HTMLButtonElement>) => {
        event.preventDefault();
        navigate(`/referee-new-match-request`)
    }

    return (
        <Container maxWidth="sm">
            <Zoom in={true} style={{transitionDelay: '100ms'}}>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} style={{fontSize: 48}}>Select Match</Grid>
                    <Grid item xs={12}>
                        <img src="images/8ball.png" width={108}/>
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}>
                        <TextField id="matchId" placeholder="Match Id" variant="outlined"
                                   value={matchId}
                                   onChange={(event) => setMatchId(event.target.value)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" onClick={gotoMatch}
                                style={{fontFamily: 'papyrus', fontSize: 18, width: 196}}>Go</Button>
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" onClick={createNewMatch}
                                style={{fontFamily: 'papyrus', fontSize: 18, width: 248}}>Create New Match</Button>
                    </Grid>
                </Grid>
            </Zoom>
            <RefereeBottomBar/>
        </Container>
    );
};

export default RefereeMatchSelect;
