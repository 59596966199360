import React, {useEffect, useState} from "react";
import {Avatar, Button, Container, debounce, Grid, Typography, Zoom} from "@mui/material";
import {useParams} from "react-router-dom";
import MatchData from "../../types/MatchData";
import MatchFunctions from "../match/MatchFunctions";
import Players from "../match/Players";
import MatchInfo from "../match/MatchInfo";
import RefereeBottomBar from "../RefereeBottomBar";
import RefereeService from "../../services/RefereeService";

const RefereeMatch: React.FC = () => {

    const {id} = useParams();
    const [match, setMatch] = useState<MatchData | null>()
    const [matchInfo, setMatchInfo] = useState<string | null>()
    const [zoomIn, setZoomIn] = useState(true);
    const [inningsEndButtonPressed, setInningsEndButtonPressed] = useState(false);
    const [defenseButtonPressed, setDefenseButtonPressed] = useState(false);
    const [winButtonPressed, setWinButtonPressed] = useState(false);
    const [takeControlButtonPressed, setTakeControlButtonPressed] = useState(false);
    const [leaveControlButtonPressed, setLeaveControlButtonPressed] = useState(false);
    const [switchTurnButtonPressed, setSwitchTurnButtonPressed] = useState(false);
    const [player1TurnDisplay, setPlayer1TurnDisplay] = useState('');
    const [player2TurnDisplay, setPlayer2TurnDisplay] = useState('');
    const [switchTurnDisplay, setSwitchTurnDisplay] = useState('');

    useEffect(() => {
        MatchFunctions.retrieveMatch(id, setMatch, setMatchInfo, setPlayer1TurnDisplay, setPlayer2TurnDisplay, setSwitchTurnDisplay)
    }, []);

    const update = (e: React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault()
        if ((e.target as HTMLButtonElement).name === 'finishInningButton') {
            setInningsEndButtonPressed(true)
        } else if ((e.target as HTMLButtonElement).name === 'defenseButton') {
            setDefenseButtonPressed(true)
        } else if ((e.target as HTMLButtonElement).name === 'winButton') {
            setWinButtonPressed(true)
        } else if ((e.target as HTMLButtonElement).name === 'takeControlButton') {
            setTakeControlButtonPressed(true)
        } else if ((e.target as HTMLButtonElement).name === 'leaveControlButton') {
            setLeaveControlButtonPressed(true)
        } else if ((e.target as HTMLButtonElement).name === 'switchTurnButton') {
            setSwitchTurnButtonPressed(true)
        }
        setZoomIn(false)
    }

    const mainZoomEnd = () => {
        if (inningsEndButtonPressed) {
            setInningsEndButtonPressed(false)
            RefereeService.finishInning(Number(match?.turnPlayerId))
                .then((matchData: any) => {
                    setMatch(matchData.data)
                    MatchFunctions.setPlayerTurnDisplays(matchData.data, setPlayer1TurnDisplay, setPlayer2TurnDisplay, setSwitchTurnDisplay)
                    setZoomIn(true)
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        } else if (defenseButtonPressed) {
            setDefenseButtonPressed(false)
            RefereeService.defense(Number(match?.turnPlayerId))
                .then((matchData: any) => {
                    setMatch(matchData.data)
                    MatchFunctions.setPlayerTurnDisplays(matchData.data, setPlayer1TurnDisplay, setPlayer2TurnDisplay, setSwitchTurnDisplay)
                    setZoomIn(true)
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        } else if (winButtonPressed) {
            setWinButtonPressed(false)
            RefereeService.win(Number(match?.turnPlayerId))
                .then((matchData: any) => {
                    setMatch(matchData.data)
                    MatchFunctions.setPlayerTurnDisplays(matchData.data, setPlayer1TurnDisplay, setPlayer2TurnDisplay, setSwitchTurnDisplay)
                    if (matchData.data.winner !== null) {
                        setMatchInfo(`Winner is ${matchData.data.winner.name}`)
                    }
                    setZoomIn(true)
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        } else if (takeControlButtonPressed) {
            setTakeControlButtonPressed(false)
            RefereeService.takeControl(Number(localStorage.getItem('refereeId')), Number(match?.id))
                .then(() => {
                    match!.refereeId = Number(localStorage.getItem('refereeId'))
                    setZoomIn(true)
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        } else if (leaveControlButtonPressed) {
            setLeaveControlButtonPressed(false)
            RefereeService.leaveControl(Number(localStorage.getItem('refereeId')), Number(match?.id))
                .then(() => {
                    match!.refereeId = -1
                    setZoomIn(true)
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        } else if (switchTurnButtonPressed) {
            setSwitchTurnButtonPressed(false)
            RefereeService.switchPlayers(match?.id)
                .then((matchData: any) => {
                    setMatch(matchData.data)
                    MatchFunctions.setPlayerTurnDisplays(matchData.data, setPlayer1TurnDisplay, setPlayer2TurnDisplay, setSwitchTurnDisplay)
                    setZoomIn(true)
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        }
    }

    return (
        <Container maxWidth="sm">
            {match ? (
                <Container maxWidth="sm">
                    <Zoom in={zoomIn} style={{transitionDelay: '100ms'}} onTransitionEnd={mainZoomEnd}>
                        <Grid container spacing={4} alignItems="center">
                            <Players match={match}
                                     player1TurnDisplay={player1TurnDisplay}
                                     player2TurnDisplay={player2TurnDisplay}
                                     switchTurnDisplay={switchTurnDisplay}
                                     update={update}/>

                            <Grid container spacing={4} alignItems="center" style={{marginLeft:2}}>
                                <Grid item xs={4} sm={3}>
                                    <Avatar src={`images/${match?.playerOne.id}.jpg`}
                                                                           sx={{width: 96, height: 96, border: '1px solid lightgray'}}/>
                                </Grid>
                                <Grid item xs={4} sm={5}>
                                    <Grid container spacing={4} alignItems="center">
                                        <Grid item xs={4} sm={5} style={{fontSize: 28}}>
                                            <div>{match?.playerOneWon}</div>
                                        </Grid>
                                        <Grid item xs={2} sm={2} style={{fontSize: 18}}>-</Grid>
                                        <Grid item xs={4} sm={5} style={{fontSize: 28}}>
                                            <div>{match?.playerTwoWon}</div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                    <Avatar src={`images/${match?.playerTwo.id}.jpg`}
                                                                           sx={{width: 96, height: 96, border: '1px solid lightgray'}}/>
                                </Grid>

                                <MatchInfo match={match}/>

                                {match?.winner === null ?
                                        match?.refereeId === Number(localStorage.getItem('refereeId')) ?
                                            (
                                                <div>
                                                    <Grid container spacing={3} style={{marginTop: 16}} alignItems="center">
                                                        <Grid item xs={2} sm={1}></Grid>
                                                        <Grid item xs={5} sm={5}>
                                                            <Button variant="contained" name='defenseButton' onClick={debounce(update, 100)}
                                                                    style={{
                                                                        fontFamily: 'papyrus',
                                                                        fontSize: 14,
                                                                        width: 136
                                                                    }}>Defense</Button>
                                                        </Grid>
                                                        <Grid item xs={5} sm={5}>
                                                            <Button variant="contained" name='finishInningButton' onClick={debounce(update, 100)}
                                                                    style={{fontFamily: 'papyrus', fontSize: 14, width: 136}}>Finish
                                                                Inning</Button>
                                                        </Grid>
                                                        <Grid item xs={0} sm={1}></Grid>
                                                    </Grid>

                                                    <Grid container spacing={3} style={{marginTop: 16}}>
                                                        <Grid item xs={2} sm={0}></Grid>
                                                        <Grid item xs={10} sm={12}>
                                                            <Button variant="contained" name='winButton' onClick={debounce(update, 100)}
                                                                    style={{
                                                                        fontFamily: 'papyrus',
                                                                        fontSize: 14,
                                                                        width: 136
                                                                    }}>Win</Button>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={3} style={{marginTop: 16}}>
                                                        <Grid item xs={2} sm={0}></Grid>
                                                        <Grid item xs={10} sm={12}>
                                                            <Button variant="contained" name='leaveControlButton' onClick={debounce(update, 100)}
                                                                    style={{
                                                                        fontFamily: 'papyrus',
                                                                        fontSize: 14,
                                                                        width: 176
                                                                    }}>Leave Control</Button>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            )
                                            :
                                            (
                                                <Grid container spacing={3} style={{marginTop: 16}}>
                                                    <Grid item xs={12} sm={12}>
                                                        <Button variant="contained" name='takeControlButton' onClick={debounce(update, 100)}
                                                                style={{
                                                                    fontFamily: 'papyrus',
                                                                    fontSize: 14,
                                                                    width: 176
                                                                }}>Take Control</Button>
                                                    </Grid>
                                                </Grid>
                                            )
                                    : (
                                        <Grid item xs={12} sm={12}>
                                            <Typography fontSize={36} fontFamily={'papyrus'}>{matchInfo}</Typography>
                                        </Grid>
                                    )}
                            </Grid>
                        </Grid>
                    </Zoom>

                    <RefereeBottomBar/>
                </Container>
            ) : (<div></div>) }
        </Container>
    );
};
export default RefereeMatch;
