import axios from "axios";

export const getToken = () => localStorage.getItem("jwttoken");

export const getAuthorizationHeader = () => `Bearer ${getToken()}`;

export default axios.create({
    baseURL: "https://thepooltraining.com/service/billiards/api",
    //baseURL: "http://localhost:8080/billiards/api",
    headers: {
        "Content-type": "application/json",
        "Authorization": getAuthorizationHeader()
    }
});

