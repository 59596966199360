import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {
    Avatar,
    Button,
    Container,
    Grid, IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Zoom
} from "@mui/material";
import PlayerV2Data from "../../types/PlayerV2Data";
import Billiard2Service from "../../services/BilliardV2Service";
import BottomBar from "../BottomBar";
import BilliardV2Service from "../../services/BilliardV2Service";
import Compressor from "compressorjs";

const Player: React.FC = () => {
    const navigate = useNavigate();
    const [player, setPlayer] = useState<PlayerV2Data | null>()
    const [avatar, setAvatar] = useState<string | null>()
    const {id} = useParams();

    const retrievePlayer = (id: any) => {
        Billiard2Service.getPlayer(id)
            .then((player: any) => {
                setPlayer(player.data)
                setAvatar(localStorage.getItem('avatar'))
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    useEffect(() => {
        retrievePlayer(id)
        localStorage.setItem('avatar', `images/${id}.jpg?` + new Date().getMilliseconds())
    }, []);

    const requestNewMatch = (event: React.FormEvent<HTMLButtonElement>) => {
        event.preventDefault();
        navigate(`/new-match-request`)
    }

    const matchRequest = (event: React.FormEvent<HTMLButtonElement>) => {
        event.preventDefault();
        navigate(`/match-request/${localStorage.getItem('playerId')}`)
    }

    const resumeMatch = (event: React.FormEvent<HTMLButtonElement>) => {
        event.preventDefault();
        navigate(`/match/${player?.ongoingMatchId}`)
    }

    const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;
        const selectedFiles = files as FileList;

        new Compressor(selectedFiles?.[0], {
            quality: 0.6,
            success: (compressedResult) => {
                BilliardV2Service.uploadAvatar(compressedResult)
                    .then(() => {
                        retrievePlayer(id)
                        localStorage.setItem('avatar', `images/${id}.jpg?` + new Date().getMilliseconds())
                    })
                    .catch((e: Error) => {
                        console.log(e);
                    });
            },
        });
    };

    return (
        <Container maxWidth="sm">
            {player ? (
                <Container maxWidth="sm">
                    <Zoom in={true} style={{transitionDelay: '100ms'}}>
                        <div style={{width: '90%'}}>
                            <Grid container spacing={3} alignItems="center">
                                <Grid item xs={6} sm={6}>
                                    <div style={{alignSelf: 'center', justifyContent: "center", alignItems: "center", display: 'flex'}}>
                                        <input accept="image/jpeg" id="icon-button-file" type="file" style={{ display: "none" }} onChange={handleAvatarChange}/>
                                        <label htmlFor="icon-button-file">
                                            <IconButton color="primary" aria-label="upload picture" component="span">
                                                <Avatar src={avatar!} sx={{ width: 144, height: 144, border: '1px solid lightgray' }}/>
                                            </IconButton>
                                        </label>
                                    </div>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <Typography fontSize={36} fontFamily={'papyrus'}
                                                sx={{wordBreak: "break-word", textAlign: 'left'}}>{player!.name}</Typography>
                                </Grid>

                                <Grid item xs={12} sm={12} maxWidth="sm">
                                    <TableContainer component={Paper} elevation={8}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{fontSize: 10}}>Innings (Total)</TableCell>
                                                    <TableCell style={{fontSize: 10}}>Defenses (Total)</TableCell>
                                                    <TableCell style={{fontSize: 10}}>Innings (Win)</TableCell>
                                                    <TableCell style={{fontSize: 10}}>Defenses (Win)</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>{player!.totalInnings}</TableCell>
                                                    <TableCell>{player!.totalDefenses}</TableCell>
                                                    <TableCell>{player!.winTotalInnings}</TableCell>
                                                    <TableCell>{player!.winTotalDefenses}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item xs={12} sm={12} maxWidth="sm">
                                    <TableContainer component={Paper} elevation={8}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{fontSize: 10}}>Wins</TableCell>
                                                    <TableCell style={{fontSize: 10}}>Loses</TableCell>
                                                    <TableCell style={{fontSize: 10}}>Win %</TableCell>
                                                    <TableCell style={{fontSize: 10}}>Skill</TableCell>
                                                    <TableCell style={{fontSize: 10}}>APA Rank</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>{player!.wins}</TableCell>
                                                    <TableCell>{player!.loses}</TableCell>
                                                    <TableCell>{player!.winningPercentage}</TableCell>
                                                    <TableCell>{player!.skill}</TableCell>
                                                    <TableCell>{player!.apaRank}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    {player.ongoingMatchId === null ?
                                        player.matchRequest === null ?
                                            (
                                                <Button variant="contained" onClick={requestNewMatch}
                                                        sx={{fontFamily: 'papyrus', width: 216}}>Request New
                                                    Match</Button>
                                            ) :
                                            (
                                                <Button variant="contained" onClick={matchRequest}
                                                        sx={{fontFamily: 'papyrus', width: 196}}>Match
                                                    Request</Button>
                                            )
                                        :
                                        (
                                            <Button variant="contained" onClick={resumeMatch}
                                                    style={{fontFamily: 'papyrus'}}>Resume Match</Button>
                                        )}
                                </Grid>
                            </Grid>
                        </div>
                    </Zoom>
                    <BottomBar/>
                </Container>
            ) : (<div></div>)
            }
        </Container>
    )

};

export default Player;
