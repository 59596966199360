import React from 'react';
import {BottomNavigation, BottomNavigationAction, Paper} from "@mui/material";
import {useNavigate} from "react-router-dom";

const RefereeBottomBar: React.FC = () => {
    const navigate = useNavigate();

    return (
        <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={3}>
            <BottomNavigation showLabels style={{
                background: 'linear-gradient(to right, #4ca1af,transparent)',
                backgroundColor: '#c4e0e5'
            }}
                              onChange={(event, newValue) => {
                                  if (newValue === 0) {
                                      navigate(`/referee/match/select`)
                                  } else if (newValue === 1) {
                                      navigate(`/referee`)
                                  }
                              }}>
                <BottomNavigationAction label="Match Select"/>
                <BottomNavigationAction label="Logout"/>
            </BottomNavigation>
        </Paper>
    );
};

export default RefereeBottomBar;
