import http from "../http-common";
import PlayerV2Data from "../types/PlayerV2Data";
import {MatchRequest} from "./request/MatchRequest";
import MatchRequestData from "../types/MatchRequestData";
import MatchData from "../types/MatchData";
import {NewPlayerRegistrationRequest} from "./request/NewPlayerRegistrationRequest";
import {AuthenticationRequest} from "./request/AuthenticationRequest";
import {AuthenticationResponse} from "./response/AuthenticationResponse";

const authenticate = (username: any, password: any) => {
    return http.post<AuthenticationResponse>(`/authenticate`, new AuthenticationRequest(username, password));
};

const register = (username: any, email: any, password: any) => {
    return http.post<PlayerV2Data>(`/v2/player/register`, new NewPlayerRegistrationRequest(username, email, password));
};

const getPlayer = (id: any) => {
    return http.get<PlayerV2Data>(`/v2/player/${id}`);
};

const search = (query: any) => {
    return http.get<Array<PlayerV2Data>>(`/v2/player/search?query=${query}`);
};

const requestMatch = (requesterPlayerId: any, requestedPlayerId: any, raceTo: number) => {
    return http.post(`/v1/match/request`, new MatchRequest(requesterPlayerId, requestedPlayerId, raceTo));
};

const retrieveMatchRequestById = (requestId: any) => {
    return http.get<MatchRequestData>(`/v1/match/request/${requestId}`);
};

const retrieveMatchRequest = (playerId: any) => {
    return http.get<MatchRequestData>(`/v1/match/${playerId}/request`);
};

const cancelMatchRequest = (matchRequestId: number) => {
    return http.put(`/v1/match/request/cancel/${matchRequestId}`);
};

const acceptMatchRequest = (matchRequestId: number) => {
    return http.put<MatchRequestData>(`/v1/match/request/accept/${matchRequestId}`);
};

const getMatch = (matchId: number) => {
    return http.get<MatchData>(`/v1/match/${matchId}`);
};

const getMatchByRequestId = (requestId: number) => {
    return http.get<MatchData>(`/v1/match/by-request/${requestId}`);
};

const finishInning = (playerId: number) => {
    return http.put<MatchData>(`/v1/match/finish-inning/${playerId}`);
};

const defense = (playerId: number) => {
    return http.put<MatchData>(`/v1/match/defense/${playerId}`);
};

const win = (playerId: number) => {
    return http.put<MatchData>(`/v1/match/win/${playerId}`);
};

const switchPlayers = (playerId: number, matchId: number) => {
    return http.put<MatchData>(`/v1/match/switch-players/${playerId}/${matchId}`);
};

const retrieveMatches = (playerId: number, limit: number) => {
    return http.get<MatchData>(`/v1/match/player/${playerId}?limit=${limit}`);
};

const uploadAvatar = (file: Blob): Promise<any> => {
    let formData = new FormData();
    formData.append("file", file);
    return http.post("/v2/player/avatar", formData, {headers: {"Content-Type": "multipart/form-data"}});
};

const Billiard2Service = {
    authenticate,
    register,
    getPlayer,
    search,
    requestMatch,
    retrieveMatchRequestById,
    retrieveMatchRequest,
    cancelMatchRequest,
    acceptMatchRequest,
    getMatch,
    getMatchByRequestId,
    finishInning,
    defense,
    win,
    switchPlayers,
    retrieveMatches,
    uploadAvatar
};

export default Billiard2Service;
