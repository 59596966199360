import React, {useEffect, useState} from "react";
import {Box, Container, Grid, Zoom} from "@mui/material";
import {useParams} from "react-router-dom";
import BilliardV2Service from "../../services/BilliardV2Service";
import {DataGrid, GridColDef, GridToolbarQuickFilter} from "@mui/x-data-grid";
import BottomBar from "../BottomBar";

const PlayerMatches: React.FC = () => {

    let pageSize = 15;
    const {playerId} = useParams();
    const [matches, setMatches] = useState([])
    const [rowCount, setRowCount] = useState(0)

    const columns: GridColDef[] = [
        {field: 'playerOne.name', headerName: 'Player1', width: 90,
            valueGetter: (params) => { return params.row.playerOne.name },
            cellClassName: (params) => { return params.row.playerOneId === params.row.winner.id ? 'winner': 'loser' }},
        {field: 'playerOneInnings', headerName: 'I1', width: 20},
        {field: 'playerOneDefenses', headerName: 'D1', width: 20},
        {field: 'playerOneWon', headerName: 'W1', width: 20},
        {field: 'playerTwo.name', headerName: 'Player2', width: 90,
            valueGetter: (params) => { return params.row.playerTwo.name },
            cellClassName: (params) => { return params.row.playerTwoId === params.row.winner.id ? 'winner': 'loser' }},
        {field: 'playerTwoInnings', headerName: 'I2', width: 20},
        {field: 'playerTwoDefenses', headerName: 'D2', width: 20},
        {field: 'playerTwoWon', headerName: 'W2', width: 20},
        {field: 'matchDateStr', headerName: 'Date', width: 90}
    ];

    useEffect(() => {
        retrieveMatches(playerId, 100)
    }, []);

    const retrieveMatches = (playerId: any, limit: any) => {
        BilliardV2Service.retrieveMatches(playerId, limit)
            .then((matches: any) => {
                setMatches(matches.data)
                setRowCount(Math.floor(matches.data.length / rowCount) + (matches.data.length % pageSize > 0 ? 1 : 0))
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    function QuickSearchToolbar() {
        return (
            <Box sx={{ p: 0.5, pb: 0, }}>
                <GridToolbarQuickFilter/>
            </Box>
        );
    }

    return (
        <Container maxWidth="sm">
            <Zoom in={true} style={{transitionDelay: '100ms'}}>
                <div style={{height: 500, width: '100%'}}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={12} style={{fontSize: 48}}>Matches</Grid>
                    </Grid>
                    <DataGrid
                        rows={matches}
                        columns={columns}
                        slots={{ toolbar: QuickSearchToolbar }}
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: 10, page: 0 },
                            },
                        }}
                        sx={{
                            boxShadow: 4,
                            bgcolor: 'white',
                            border: 1,
                            borderColor: 'primary.light',
                            '& .MuiDataGrid-cell:hover': {
                                color: 'primary.main',
                            },
                            fontSize:12
                        }}/>
                </div>
            </Zoom>
            <BottomBar/>
        </Container>
    );
};
export default PlayerMatches;
