import {FC} from "react";
import Login from "./components/player/Login";
import Player from "./components/player/Player";
import Training from "./components/training/Training";
import Trainings from "./components/training/Trainings";
import PlayerV2 from "./components/player/PlayerV2";
import NewMatchRequest from "./components/match/NewMatchRequest";
import MatchRequest from "./components/match/MatchRequest";
import Match from "./components/match/Match";
import RefereeMatch from "./components/referee/RefereeMatch";
import RefereeLogin from "./components/referee/RefereeLogin";
import RefereeMatchSelect from "./components/referee/RefereeMatchSelect";
import RefereeNewMatch from "./components/referee/RefereeNewMatch";
import PlayerMatches from "./components/match/PlayerMatches";
import Register from "./components/player/Register";

// interface
interface Route {
    key: string,
    title: string,
    path: string,
    enabled: boolean,
    component: FC<{}>
}

export const routes: Array<Route> = [
    {
        key: 'login-route',
        title: 'Login',
        path: '/',
        enabled: true,
        component: Login
    },
    {
        key: 'referee-login-route',
        title: 'Referee Login',
        path: '/referee',
        enabled: true,
        component: RefereeLogin
    },
    {
        key: 'player-v2-route',
        title: 'Player V2',
        path: '/player-v2/:id',
        enabled: true,
        component: PlayerV2
    },
    {
        key: 'player-v2-matches-route',
        title: 'Player Matches',
        path: '/player-v2/:playerId/matches',
        enabled: true,
        component: PlayerMatches
    },
    {
        key: 'new-match-request-route',
        title: 'New Match Request',
        path: '/new-match-request',
        enabled: true,
        component: NewMatchRequest
    },
    {
        key: 'referee-new-match-request-route',
        title: 'New Match Request',
        path: '/referee-new-match-request',
        enabled: true,
        component: RefereeNewMatch
    },
    {
        key: 'match-request-route',
        title: 'Match Request',
        path: '/match-request/:playerId',
        enabled: true,
        component: MatchRequest
    },
    {
        key: 'match-route',
        title: 'Match',
        path: '/match/:id',
        enabled: true,
        component: Match
    },
    {
        key: 'referee-match-route',
        title: 'RefereeMatch',
        path: '/referee/match/:id',
        enabled: true,
        component: RefereeMatch
    },
    {
        key: 'referee-match-select-route',
        title: 'RefereeMatchSelect',
        path: '/referee/match/select',
        enabled: true,
        component: RefereeMatchSelect
    },
    {
        key: 'player-route',
        title: 'Player',
        path: '/player/:id',
        enabled: true,
        component: Player
    },
    {
        key: 'training-route',
        title: 'Training',
        path: '/training/:id',
        enabled: true,
        component: Training
    },
    {
        key: 'trainings-route',
        title: 'Trainings',
        path: '/trainings/:playerId',
        enabled: true,
        component: Trainings
    },
    {
        key: 'register-route',
        title: 'Register',
        path: '/register',
        enabled: true,
        component: Register
    }
]
