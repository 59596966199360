import React, {useEffect, useState} from "react";
import {Avatar, Button, Container, debounce, Grid, Typography, Zoom} from "@mui/material";
import {useParams} from "react-router-dom";
import Billiard2Service from "../../services/BilliardV2Service";
import MatchData from "../../types/MatchData";
import MatchFunctions from "./MatchFunctions";
import Players from "./Players";
import MatchInfo from "./MatchInfo";
import BottomBar from "../BottomBar";
import ClientWsMessage from "../../types/ws/ClientWsMessage";
import RegisterMatchWsMessage from "../../types/ws/RegisterMatchWsMessage";
import RefereeService from "../../services/RefereeService";

const Match: React.FC = () => {

    const {id} = useParams();
    const [match, setMatch] = useState<MatchData | null>()
    const [matchInfo, setMatchInfo] = useState<string | null>()
    const [zoomIn, setZoomIn] = useState(true);
    const [inningsEndButtonPressed, setInningsEndButtonPressed] = useState(false);
    const [defenseButtonPressed, setDefenseButtonPressed] = useState(false);
    const [winButtonPressed, setWinButtonPressed] = useState(false);
    const [switchTurnButtonPressed, setSwitchTurnButtonPressed] = useState(false);
    const [player1TurnDisplay, setPlayer1TurnDisplay] = useState('');
    const [player2TurnDisplay, setPlayer2TurnDisplay] = useState('');
    const [switchTurnDisplay, setSwitchTurnDisplay] = useState('');

    useEffect(() => {
        MatchFunctions.retrieveMatch(id, setMatch, setMatchInfo, setPlayer1TurnDisplay, setPlayer2TurnDisplay, setSwitchTurnDisplay)
        setMatchInfo("Opponent's Turn")

        //const ws = new WebSocket('ws://localhost:8080/match');
        const ws = new WebSocket('wss://thepooltraining.com/match');
        ws.onopen = (evt: any) => {
            const registerMatchWsMessage: RegisterMatchWsMessage = {
                playerId: Number(localStorage.getItem('playerId')),
                matchId: Number(id)
            }
            const clientWsMessage: ClientWsMessage = {
                type: 'REGISTER_MATCH',
                messageString: JSON.stringify(registerMatchWsMessage)
            };
            ws.send(JSON.stringify(clientWsMessage))
        };

        ws.onmessage = (evt: MessageEvent) => {
            if (JSON.parse(evt.data).type === 'REFRESH_MATCH') {
                setZoomIn(false)
            }
        };
    }, []);

    const update = (e: React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault()
        if ((e.target as HTMLButtonElement).name === 'finishInningButton') {
            setInningsEndButtonPressed(true)
        } else if ((e.target as HTMLButtonElement).name === 'defenseButton') {
            setDefenseButtonPressed(true)
        } else if ((e.target as HTMLButtonElement).name === 'winButton') {
            setWinButtonPressed(true)
        } else if ((e.target as HTMLButtonElement).name === 'switchTurnButton') {
            setSwitchTurnButtonPressed(true)
        }
        setZoomIn(false)
    }

    const mainZoomEnd = () => {
        if (inningsEndButtonPressed) {
            setInningsEndButtonPressed(false)
            Billiard2Service.finishInning(Number(localStorage.getItem('playerId')))
                .then((matchData: any) => {
                    setMatch(matchData.data)
                    MatchFunctions.setPlayerTurnDisplays(matchData.data, setPlayer1TurnDisplay, setPlayer2TurnDisplay, setSwitchTurnDisplay)
                    setZoomIn(true)
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        } else if (defenseButtonPressed) {
            setDefenseButtonPressed(false)
            Billiard2Service.defense(Number(localStorage.getItem('playerId')))
                .then((matchData: any) => {
                    setMatch(matchData.data)
                    MatchFunctions.setPlayerTurnDisplays(matchData.data, setPlayer1TurnDisplay, setPlayer2TurnDisplay, setSwitchTurnDisplay)
                    setZoomIn(true)
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        } else if (winButtonPressed) {
            setWinButtonPressed(false)
            Billiard2Service.win(Number(localStorage.getItem('playerId')))
                .then((matchData: any) => {
                    setMatch(matchData.data)
                    if (matchData.data.winner !== null) {
                        setMatchInfo(`Winner is ${matchData.data.winner.name}`)
                        setPlayer1TurnDisplay('none')
                        setPlayer2TurnDisplay('none')
                    }
                    setZoomIn(true)
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        } else if (switchTurnButtonPressed) {
            setSwitchTurnButtonPressed(false)
            RefereeService.switchPlayers(match?.id)
                .then((matchData: any) => {
                    setMatch(matchData.data)
                    MatchFunctions.setPlayerTurnDisplays(matchData.data, setPlayer1TurnDisplay, setPlayer2TurnDisplay, setSwitchTurnDisplay)
                    setZoomIn(true)
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        } else {
            MatchFunctions.retrieveMatch(id, setMatch, setMatchInfo, setPlayer1TurnDisplay, setPlayer2TurnDisplay, setSwitchTurnDisplay)
            setZoomIn(true)
        }
    }

    return (
        <Container maxWidth="sm">
            {match ? (
                <Container maxWidth="sm">
                    <Zoom in={zoomIn} style={{transitionDelay: '100ms'}} onTransitionEnd={mainZoomEnd}>
                        <Grid container spacing={4} alignItems="center">
                            <Players match={match!}
                                     player1TurnDisplay={player1TurnDisplay}
                                     player2TurnDisplay={player2TurnDisplay}
                                     switchTurnDisplay={switchTurnDisplay}
                                     update={update}/>

                            <Grid container spacing={4} alignItems="center" style={{marginLeft:2}}>
                                <Grid item xs={4} sm={3}>
                                    <Avatar src={`images/${match?.playerOne.id}.jpg`}
                                                                           sx={{width: 96, height: 96, border: '1px solid lightgray'}}/>
                                </Grid>
                                <Grid item xs={4} sm={5}>
                                    <Grid container spacing={4} alignItems="center">
                                        <Grid item xs={4} sm={5} style={{fontSize: 28}}>
                                            <div>{match?.playerOneWon}</div>
                                        </Grid>
                                        <Grid item xs={2} sm={2} style={{fontSize: 18}}>-</Grid>
                                        <Grid item xs={4} sm={5} style={{fontSize: 28}}>
                                            <div>{match?.playerTwoWon}</div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} sm={4}>
                                    <Avatar src={`images/${match?.playerTwo.id}.jpg`}
                                                                           sx={{width: 96, height: 96, border: '1px solid lightgray'}}/>
                                </Grid>

                                <MatchInfo match={match!}/>

                                {match?.refereeId !== null ?
                                    (
                                        <Grid item xs={12} sm={12}>
                                            <Typography fontSize={36} fontFamily={'papyrus'}>Match has referee!</Typography>
                                        </Grid>
                                    )
                                    :
                                    match?.winner === null && match?.turnPlayerId === Number(localStorage.getItem('playerId')) ?
                                        (
                                            <Grid container spacing={5} style={{marginTop: 16}} alignItems="center">
                                                <Grid item xs={1} sm={1}/>
                                                <Grid item xs={5} sm={5}>
                                                    <Button variant="contained" name='defenseButton' onClick={debounce(update, 100)}
                                                            style={{
                                                                fontFamily: 'papyrus',
                                                                fontSize: 14,
                                                                width: 136
                                                            }}>Defense</Button>
                                                </Grid>
                                                <Grid item xs={5} sm={5}>
                                                    <Button variant="contained" name='finishInningButton' onClick={debounce(update, 100)}
                                                            style={{fontFamily: 'papyrus', fontSize: 14, width: 136}}>Finish Inning</Button>
                                                </Grid>
                                                <Grid item xs={1} sm={1}/>

                                                <Grid item xs={0} sm={0}/>
                                                <Grid item xs={8} sm={12}>
                                                    <Button variant="contained" name='winButton' onClick={debounce(update, 100)}
                                                            style={{
                                                                fontFamily: 'papyrus',
                                                                fontSize: 14,
                                                                width: 136
                                                            }}>Win</Button>
                                                </Grid>
                                                <Grid item xs={4} sm={0}/>
                                            </Grid>
                                        )
                                        : (
                                            <Grid item xs={12} sm={12}>
                                                <Typography fontSize={36} fontFamily={'papyrus'}>{matchInfo}</Typography>
                                            </Grid>
                                        )
                                    }

                            </Grid>
                        </Grid>

                    </Zoom>

                    <BottomBar/>
                </Container>
            ) : (<div></div>) }
        </Container>
    );
};
export default Match;
