import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Button, Container, Grid, TextField, Zoom} from "@mui/material";
import Billiard2Service from "../../services/BilliardV2Service";
import jwtDecode, { JwtPayload } from "jwt-decode";
import JwtData from "../../types/JwtData";
import httpCommon from "../../http-common";

const Login: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.clear()
    }, []);

    const authenticate = (event: React.FormEvent<HTMLButtonElement>) => {
        event.preventDefault();

        Billiard2Service.authenticate(username, password)
            .then((response: any) => {
                localStorage.setItem('jwttoken', response.data.jwttoken);
                httpCommon.defaults.headers.Authorization = `Bearer ${response.data.jwttoken}`

                const token = jwtDecode<JwtData>(response.data.jwttoken);
                localStorage.setItem('playerId', token.id)
                localStorage.setItem('avatar', token.avatar)
                navigate(`/player-v2/${token.id}`)
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    return (
        <Container maxWidth="sm">
            <Zoom in={true} style={{transitionDelay: '100ms'}}>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} style={{fontSize: 48}}>Pool Training</Grid>
                    <Grid item xs={12}>
                        <img src="images/8ball.png" width={108}/>
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}>
                        <TextField id="userid" placeholder="Username" variant="outlined"
                                   value={username}
                                   onChange={(event) => setUsername(event.target.value)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="password" placeholder="Password" variant="outlined" type="password"
                                   value={password}
                                   onChange={(event) => setPassword(event.target.value)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" onClick={authenticate}
                                style={{fontFamily: 'papyrus', fontSize: 18, width: 196}}>Login</Button>
                    </Grid>
                    {/*<Grid item xs={12}>
                        <LoginSocialFacebook
                            appId={'678894467345767'}
                            fieldsProfile={
                                'id,first_name,last_name,middle_name,name,name_format,picture.width(200),short_name,email,gender'
                            }
                            onResolve={({provider, data}: IResolveParams) => {
                                BilliardService.socialMediaLogin(data?.id, data?.first_name, provider)
                                    .then((playerData: any) => {
                                        globalThis.window.player = playerData
                                        globalThis.window.pic = data?.picture.data.url
                                        navigate(`/player/${playerData.data.id}`)
                                    })
                                    .catch((e: Error) => {
                                        console.log(e);
                                    });
                            }}
                            onReject={err => {
                                console.log(err);
                            }}
                        >
                            <FacebookLoginButton/>
                        </LoginSocialFacebook>
                        {<LoginSocialInstagram
                            client_id={'434764865506388'}
                            client_secret={'c515210db749a8f1f095b18a37897ebd'}
                            redirect_uri={'https://thepooltraining.com/l'}
                            onResolve={({ provider, data }: IResolveParams) => {
                                console.log(data)
                                BilliardService.socialMediaLogin(data?.user_id, data?.username, provider)
                                    .then((playerData: any) => {
                                        globalThis.window.player = playerData
                                        navigate(`/player/${playerData.data.id}`)
                                    })
                                    .catch((e: Error) => {
                                        console.log(e);
                                    });
                            }}
                            onReject={(err: any) => {
                                console.log(err);
                            }}
                        >
                            <InstagramLoginButton />
                        </LoginSocialInstagram>}
                    </Grid>*/}
                    <Grid item xs={12} style={{marginTop: 32}}>
                        <Button variant="contained" onClick={() => navigate(`/referee`)}
                                style={{fontFamily: 'papyrus', fontSize: 13, width: 160}}>Referee Login</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" onClick={() => navigate(`/register`)}
                                style={{fontFamily: 'papyrus', fontSize: 13, width: 160}}>New Player</Button>
                    </Grid>
                </Grid>
            </Zoom>
        </Container>
    );
};

export default Login;
