import React from 'react';
import {Button, Container, debounce, Grid, Typography} from "@mui/material";
import MatchData from "../../types/MatchData";


interface PlayersProps {
    match: MatchData,
    player1TurnDisplay: any
    player2TurnDisplay: any,
    switchTurnDisplay: any
    update: any,
}

const Players: React.FC<PlayersProps> = ({match, player1TurnDisplay, player2TurnDisplay, switchTurnDisplay, update}) => {

    return (
        <Container maxWidth={"sm"} style={{marginTop: 16}}>
            <Grid container spacing={3} style={{marginTop: 16, display: `${switchTurnDisplay}`}} >
                <Grid item xs={12} sm={12} alignItems={"center"}>
                    <Button variant="contained" name='switchTurnButton' onClick={debounce(update, 100)}
                            style={{
                                fontFamily: 'papyrus',
                                fontSize: 14,
                                width: 136
                            }}>Switch Turn</Button>
                </Grid>
            </Grid>

            <Grid container spacing={4} alignItems="center">
                <Grid item xs={5} sm={3}>
                    <img src="images/8ball.png" width={36} style={{display: `${player1TurnDisplay}`}}/>
                </Grid>
                <Grid item xs={4} sm={5}></Grid>
                <Grid item xs={3} sm={4}>
                    <img src="images/8ball.png" width={36} style={{display: `${player2TurnDisplay}`}}/>
                </Grid>
            </Grid>

            <Grid container spacing={4} alignItems="center">
                <Grid item xs={5} sm={3} style={{fontSize: 20}}>{match?.playerOne.name}</Grid>
                <Grid item xs={4} sm={5}><Typography fontSize={16} fontFamily={'papyrus'}>Match ID: {match.id}</Typography></Grid>
                <Grid item xs={3} sm={4} style={{fontSize: 20}}>{match?.playerTwo.name}</Grid>
            </Grid>
        </Container>
    );
};

export default Players;

